// material-ui
import { ReactComponent as LogoLight } from "assets/images/logo-type.svg";
import { useSelector } from "react-redux";

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
	const siteInfo = useSelector((state) => state.siteInfo);
	// const theme = useTheme();
	// const logo = theme.palette.mode === "light" ? logoLight : logoDark;

	return (
		<LogoLight
			height={"100"}
			width={"200"}
			// style={{ objectFit: "cover" }}
		/>
	);
};

export default Logo;
