import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Avatar, Box, ButtonBase } from "@mui/material";

// project imports
import LogoSection from "../LogoSection";
import ProfileSection from "./ProfileSection";

// assets
import { IconMenu2 } from "@tabler/icons-react";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
	const theme = useTheme();

	return (
		<>
			{/* logo & toggler button */}
			<Box
				sx={{
					width: 228,
					gap: 4,
					display: "flex",
					justifyContent: "space-around",
					[theme.breakpoints.down("md")]: {
						width: "auto",
					},
				}}
			>
				<Box
					component="span"
					sx={{
						display: { xs: "none", md: "block" },
						flexGrow: 1,
						maxWidth: "50%",
					}}
				>
					<LogoSection />
				</Box>
				<ButtonBase
					sx={{ borderRadius: "12px", overflow: "hidden" }}
					disableRipple
				>
					<Avatar
						variant="rounded"
						sx={{
							...theme.typography.commonAvatar,
							...theme.typography.mediumAvatar,
							...theme.typography.menuButton,
							transition: "all .2s ease-in-out",
							"&:hover": {
								background: theme.palette.secondary.dark,
								color: theme.palette.secondary.light,
							},
						}}
						onClick={handleLeftDrawerToggle}
						color="inherit"
					>
						<IconMenu2 stroke={1.5} size="1.3rem" />
					</Avatar>
				</ButtonBase>
			</Box>

			<Box sx={{ flexGrow: 1 }} />
			<Box sx={{ flexGrow: 1 }} />
			{/* <ThemeButton /> */}
			<ProfileSection />
		</>
	);
};

Header.propTypes = {
	handleLeftDrawerToggle: PropTypes.func,
};

export default Header;
