import { Outlet } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { AppBar, Box, CssBaseline, Toolbar, Container } from "@mui/material";
import Header from "./Header";

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => {
	const theme = useTheme();

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				minHeight: "100vh",
			}}
		>
			<CssBaseline />
			<AppBar
				enableColorOnDark
				position="fixed"
				color="inherit"
				elevation={0}
				sx={{
					bgcolor: theme.palette.background.header,
					flex: "none",
				}}
			>
				<Container>
					<Toolbar>
						<Header />
					</Toolbar>
				</Container>
			</AppBar>
			<Box
				sx={{ flex: "1 1 auto", height: "100%", overflow: "auto" }}
				marginTop={"100px"}
			>
				<Outlet />
			</Box>
		</Box>
	);
};

export default MinimalLayout;
